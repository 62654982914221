import {Component} from "react";
import PropTypes from "prop-types";
import './CSS/InfoBox.css';

function create_info_lines(required_info, my_info) {
    let type = my_info["system_type"];
    required_info["sn_new"] = { title: "#️⃣ SN New", value: my_info["sn_new"] };
    if (type === "SC" || type === "SP") {
        required_info["system_info"] = { title: "🏗️ Apparecchio", value: my_info["system_info"] };
        required_info["company_number"] = { title: "🛠️ Numero di fabbrica", value: my_info.company_number };
        required_info["marca"] = { title: "🏷️ Marca", value: `${my_info.marca} ${my_info.modello && `(${my_info.modello})`}`};
        required_info["portata"] = { title: "🅿️ Portata", value: my_info["portata"] };
    } else if (type === "GVR") {
        required_info["system_info"] = { title: "🛢️ GVR", value: my_info[""] };
        // Mostro il tipo, l'anno, il fabbricante, numero di fabbrica e modello
        // Un pulsante per mostrare le informazioni aggiuntive della tabella
        //Se presente: supericie, producibilità, potenza, capacità totale
        // Breve descrizione
    }

}

export class InfoBox extends Component {
    render() {
    const { info, type } = this.props;
    const required_info = {};
    const required_info_box = [];
    let my_info = {
        name: "Gianfranchello carretto di frutta e verdura srl",
        city: "Città di castello laggiù",
        province: "BO",
        street: "Via della frutta",
        street_number: "123",
        sn_new: "1991/0/19023/MI",
        system_type: "SC",
        system_info: "Turbo gru a razzi con propulsione a vapore",
        company_number: "1234567890",
        marca: "Mingazzini",
        modello: "Mingazzini 1234",
        anno: 2010,
        portata: "1000 - 2 Pers",

    }
    console.log(info);
    let titolo = "Informazioni";
    let section_name = "generic";
    if (type === "client") {
        titolo = "Informazioni cliente";
        section_name = type;
        required_info["name"] = { title: "🏭 Nome", value: my_info.name };
        required_info["city"] = { title: "🏙️ Città", value: `${my_info.city} ${my_info.province && `(${my_info.province})`}` };
        required_info["street"] = { title: "📍 Via", value: `${my_info.street} ${my_info.street_number && `(${my_info.street_number})`}` };
    } else if(type === "equipment") {
        titolo = "Informazioni attrezzatura";
        section_name = type;
        create_info_lines(required_info, my_info);
        console.log("Required info: ", required_info);

        
    }

    // Crea gli elementi da visualizzare
    for (const [key, value] of Object.entries(required_info)) {
        required_info_box.push(<InfoBoxLine label={value.title} value={value.value} key={key} />);
    }
    return (
        <div className={"info-box " + section_name}>
            <div className="header">
                <h2>{titolo}</h2>
                <button className="edit-button">Modifica</button>
            </div>
            <div className="details">
                {required_info_box}
            </div>
        </div>
    );
}
}

export class InfoBoxLine extends Component {
    render() {
        const { label, value } = this.props;
        return (
            <p className={"info-line"}><span className={"info-label"}>{label}:</span> <span className={"info single-line"}>{value}</span></p>
        );
    }
}


InfoBox.propTypes = {info: PropTypes.any};