import {Component} from "react";
import * as PropTypes from "prop-types";

const verify_sections_spsc = {
    "valutazione_conservazion": {
        "label": "Condizioni conservazione e manutenzione",
        "placeholder": "Inserisci una valutazione sulle condizioni generali di conservazione e manutenzione"
    },
    "device_control": {
        "label": "Esame degli organi principali",
        "placeholder": "Inserisci l'esito dell'esame (es. Regolari)"
    },
    "acc_sec": {
        "label": "Prove di funzionamento e dei dispositivi di sicurezza",
        "placeholder": "Inserisci l'esito dell'esame (es. I dispositivi di sicurezza hanno agito al momento della verifica)"
    },
    "acc_ril": {
        "label": "Configurazione e dati tecnici rilevati",
        "placeholder": "Inserisci i dettagli sulla configurazione (es. informazioni sull'apparecchio e conformità al manuale d'uso)"
    },
    "osservazioni": {
        "label": "Osservazioni",
        "placeholder": "Inserisci eventuali osservazioni (es. accordo stato regione). Non inserire qui la portata o la prossima verifica"
    }
}

export class VerifySection extends Component {
    render() {
        const {info} = this.props;
        const required_fields = []
        console.log(info);
        if (info.hasOwnProperty("system_type") && (info.system_type === "SC" || info.system_type === "SP")) {
            for (const [key, value] of Object.entries(verify_sections_spsc)) {
                required_fields.push(<InsertTextLine key={info.system_type+"_"+key} label={value.label} placeholder={value.placeholder}/>)
            }
        } else {
            console.log("Tipo non riconosciuto: ", info.system_type)
        }

        return <div className={"verify-info"}>
            <h3>Esito della verifica</h3>
            {required_fields}
        </div>
    }
}

class InsertTextLine extends Component {
    render() {
        const {label, placeholder} = this.props;
        return <div className={"insert_text_line"}>
            <span className={"short_field field_line label"}>{label}:</span>
            <textarea className={"textarea"} rows="3" cols="50" placeholder={placeholder}></textarea>
        </div>
    }
}

VerifySection.propTypes = {info: PropTypes.any};