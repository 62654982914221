import {useState} from "react";
import {AutocompleteField} from "./AutocompleteField";
import {InfoBox} from "./InfoBox";
import {VerifySection} from "./VerifySection";
import {silviodb_url} from "./index";
import {downloadReport} from "./DetailsTab"
import './CSS/NewReport.css';
import {FormDate} from "./FormDate";
import {GenericPopup} from "./GenericPopup";


function extract_relevant_reports(reports) {
    return (sn_new) => {
        let found_reports = []
        if (sn_new) {
            if (sn_new.hasOwnProperty("sn_new") && sn_new.sn_new) {
                for (const report of reports) {
                    if (report["sn_new"] === sn_new["sn_new"]) {
                        found_reports.push(report)
                    }
                }
            }
        }
        return found_reports
    };
}

// const only_gvr_fields = ["next_internal", "next_functioning", "next_integrity"];
// const only_scsp_fields = ["next_periodic"]
const ver_types_mapping = {
    "Functioning": {"text": "Funzionamento"},
    "Integrity": {"text": "Integrità"},
    "Internal": {"text": "Interna"},
    "SC": {"text": "Periodica - SC"},
    "SP": {"text": "Periodica - SP"},
    "First": {"text": "Prima Periodica"}
}

const mapping = {
    gvr: ["Functioning", "Integrity", "Internal"],
    sc: ["SC", "First"],
    sp: ["SP", "First"]
};

export const yearButtons = {
    "next_functioning": [{"text": "1 anno", "value": 1}, {"text": "2 anni", "value": 2}],
    "next_integrity": [{"text": "3 anni", "value": 3}, {"text": "10 anni", "value": 10}],
    "next_internal": [{"text": "2 anni", "value": 2}],
    "next_periodic": [{"text": "1 anno", "value": 1}]
}

export const default_value = {
    serialNumber: {},
    g_ver: new Date().toISOString().split('T')[0],
    ver_type: "",
    anno_matr: new Date().getFullYear(),
    "next_internal": "",
    "next_periodic": "",
    "next_functioning": "",
    "next_integrity": ""
}

function create_date(date) {
    return date.getDate().toString().padStart(2, '0') + '/' + (date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getFullYear();
}

export function NewReport({reports}) {
    const [info, setInfo] = useState({"selected": {}});
    const [reportMessage, setReportMessage] = useState({});
    console.log("Info: ", info)
    const findReports = extract_relevant_reports(reports);
    let selected_sn = info.selected.sn_new
    let found_report = findReports(info.selected)
    const system_type = found_report.length > 0 ? String(found_report[0]["system_type"]).toLowerCase() : "gvr";
    let verify_types = mapping[system_type]

    /**
     * This function manages the form changes
     * @param event The updated form object
     * @param field The updated field
     */
    const handleChange = (event, field) => {
        let clone = {...info}
        let target = clone.selected.sn_new
        if (typeof field === 'string') {
            clone[target][field] = event.target.value
        } else {
            clone[target][field.name] = field.value
        }
        setInfo(clone);
    };

    const updateSn = (value) => {
        /** Update the sn and stores the related information */
        let clone = {...info}
        console.log("Updating with: ", value)
        if (value != null) {
            let sn = value.sn_new
            console.log("Selezionato: ", sn)
            if (!clone.hasOwnProperty(sn)) {
                console.log("Initializing values for this value")
                clone[sn] = {...default_value};
                clone[sn].ver_type = verify_types[0]
            }
            clone.selected = value
        } else {
            clone.selected = ""
        }
        setInfo(clone)
    }

    const resetInput = () => {
        console.log("Canceling inserted values")
        let clone = {...info}
        let sn = clone.selected.sn_new
        clone[sn] = {...default_value};
        clone[sn].ver_type = verify_types[0]
        setInfo(clone)
    }

    const handleReportJoinRequest = async () => {
        const url = silviodb_url+'/verbali/genera'; // replace with your target URL
        const dismissMessage = () => {setReportMessage({})};
        let sn = info.selected.sn_new
        let date = new Date(info[sn].g_ver);
        const payload = {
            sn: sn,
            ver_type: info[sn].ver_type,
            new_info: {
                'g_ver': create_date(date),
                'anno_matr': "_"+String(date.getFullYear()),
                "next_internal": info[sn]["next_internal"],
                "next_periodic": info[sn]["next_periodic"],
                "next_functioning": info[sn]["next_functioning"],
                "next_integrity": info[sn]["next_integrity"],
            }
        };
        console.log("Info: ", payload);

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const f_name = response.headers.get('Content-Disposition').split(';', 2)[1].split('"')[1];
            response.blob().then((f) => {
                downloadReport(f, f_name)
                setReportMessage({
                    "msg": "Report pronto",
                    "img": "ok.svg",
                    "sub_msg": "Ho preparato una bozza. Ricontrolla i dati",
                    "dismiss": dismissMessage
                })
            });
            setReportMessage({
                "msg": "Preparo il report",
                "img": "pdf.svg",
                "sub_msg": "Sto scrivendo il nuovo report...",
                "dismiss": dismissMessage
            })
        } catch (error) {
            console.error('Error:', error);
        }
    };

    let selected_ver_type = "";
    if (info.hasOwnProperty(selected_sn) && info[selected_sn].hasOwnProperty("ver_type")) {
        selected_ver_type = info[selected_sn].ver_type;
    }

    return <div className={"main_fill_section"}>
        <h2 className={"title"}>Crea un nuovo verbale</h2>
        <p className={"description"}>{"Seleziona dal menu qui sotto la matricola per cui vuoi generare un nuovo verbale"}</p>
        <AutocompleteField reports={reports} serialNumber={info.selected} setSerialNumber={updateSn}/>
        {(Object.keys(info.selected).length > 0) &&
            <div className={"fill_form_div"}>
                <div className={"info-section"}>
                    <InfoBox info={info[selected_sn]} type={"client"}></InfoBox>
                    <InfoBox info={info[selected_sn]} type={"equipment"}></InfoBox>
                </div>
                <h3>Compila i campi mancanti</h3>
                <FormDate textFill={"Data della verifica"} info={info[selected_sn]} valueFill={"g_ver"}
                          onChange={handleChange}/>
                <div className={"drop_down"}>
                    <span className={"short_field field_line label"}>Tipo verifica</span>
                    <select className={"short_field "} value={selected_ver_type} onChange={e => handleChange(e, "ver_type")}>
                        {verify_types.map((elemento, index) => (
                            <option key={index} value={elemento}>
                                {ver_types_mapping[elemento]["text"]}
                            </option>
                        ))}
                    </select>
                </div>
                {(system_type === "sc" || system_type === "sp") && (
                    <FormDate textFill={"Prossima periodica"} info={info[selected_sn]} valueFill={"next_periodic"}
                              onChange={handleChange}/>
                )}
                {(system_type === "gvr") && (
                    <>
                        <FormDate textFill={"Prossimo funzionamento"} info={info[selected_sn]} valueFill={"next_functioning"}
                                  onChange={handleChange}/>
                        <FormDate textFill={"Prossima integrità"} info={info[selected_sn]} valueFill={"next_integrity"}
                                  onChange={handleChange}/>
                        <FormDate textFill={"Prossima interna"} info={info[selected_sn]} valueFill={"next_internal"}
                                  onChange={handleChange}/>
                    </>
                )}
                <VerifySection info={info[selected_sn]}></VerifySection>
                <div className={"confirmation_section"}>
                    <button className={"confirm"} onClick={handleReportJoinRequest}>Crea verbale</button>
                    <button className={"reset"} onClick={resetInput}>Annulla</button>
                </div>
                <GenericPopup status={reportMessage}/>
            </div>
        }
    </div>

}
